<div [ngClass]="theme()">
  <xds-header [attr.application-name]="brandName() + ' Client Credential Management System'" dashboard-link="/" hide-profile>
    <div slot='brand' class="brand-logo">
      @if (!brand()) {
        <img [src]="'/assets/logos/mr.png'" height="45px"/>
      }
      @else {
        <img [src]="'/assets/logos/' + brand() + '.png'" height="45px"/>
      }
    </div>
    <div slot="optional" style="display: flex; flex-direction: row; gap: 0">
      <xds-dropdown same-size="false" inline placement="bottom-end" visible-items="10" style="color: var(--xds-color-grey-dark)">
        <xds-button slot="trigger" variant="minimal" dark icon-only>
          <xds-icon slot="icon" name="person_1" size="m"></xds-icon>
        </xds-button>
        <xds-dropdown-item class="xds:is-divider" disabled>User</xds-dropdown-item>
        <xds-dropdown-item>
          <xds-text variant="body-m">{{ username() }}</xds-text>
          <xds-text variant="caption-m">{{ userEmail() }}</xds-text>
        </xds-dropdown-item>
        @if (roles().length > 0) {
          <xds-dropdown-item class="xds:is-divider" disabled>User roles</xds-dropdown-item>
          @for (role of roles(); track role; let i = $index) {
            <xds-dropdown-item disabled>{{role}}</xds-dropdown-item>
          }
        }
        <xds-dropdown-item class="xds:is-divider" disabled>Application</xds-dropdown-item>
        <xds-dropdown-item disabled>{{ 'Version' | translate }}</xds-dropdown-item>
        <xds-dropdown-item class="xds:is-divider" disabled>Other</xds-dropdown-item>
        @if (isLoggedIn()) {
          <xds-dropdown-item (click)="isLoggedIn() ? logout() : login()">Logout</xds-dropdown-item>
        }
      </xds-dropdown>
    </div>
  </xds-header>
  @if (menuItems().length > 0) {
    <xds-sidebar>
      <xds-sidenav slot="sidebar">
        @for (navItem of menuItems(); track navItem) {
          <xds-sidenav-item [attr.is-active]="isSelected(navItem.link)">
            <a [routerLink]="[navItem.link]">
              <xds-sidenav-item-content [icon]="navItem.icon" [label]="navItem.name"></xds-sidenav-item-content>
            </a>
          </xds-sidenav-item>
        }
      </xds-sidenav>
      <router-outlet></router-outlet>
    </xds-sidebar>
  } @else {
    <router-outlet></router-outlet>
  }
  <div class="version"></div>
  @if (!isLoggedIn()) {
    <div class="loginWrapper">
      <div class="welcomeMessage">
        <div>
          {{ 'messages.welcomeLogin' | translate }}
        </div>
        <xds-button (click)="login()" variant="primary"> {{ 'messages.login' | translate }} </xds-button>
      </div>
    </div>
  }
</div>
