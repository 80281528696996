import { CUSTOM_ELEMENTS_SCHEMA, Component, inject } from "@angular/core";
import { RouterLink, RouterOutlet } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";
import { Role } from "@shared/types/configuration";
import { AppStore } from "./app.store";

export interface Menu {
    name: string;
    link: string;
    icon: string;
    roles: Array<Role>;
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgClass, RouterLink, RouterOutlet, TranslateModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppComponent{
    private readonly store = inject(AppStore);

    isLoggedIn = this.store.isLoggedIn;
    initials = this.store.initials;
    roles = this.store.roles;
    menuItems = this.store.menuItems;
    brand = this.store.brand;
    brandName = this.store.brandName;
    theme = this.store.theme;
    username = this.store.username;
    userEmail = this.store.userEmail;
    currentRouteLink = this.store.currentRouteLink;

    isSelected(link: string): boolean {
        return link === this.currentRouteLink();
    }

    goToRoute(link: string) {
        this.store.goToRoute(link);
    }

    login(): void {
        this.store.login();
    }

    logout(): void {
        this.store.logout();
    }
}
