import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppComponent } from "./app.component";
import { CanActivateApplications, CanActivateInvitations } from "@shared/guards";

const routes: Routes = [
    {
        pathMatch: 'full',
        path: '',
        redirectTo: '/applications'
    },
    {
        path: 'invitations',
        loadChildren: () => import('src/app/invitation/invitation.routes').then((m) => m.invitationRoutes),
        canActivate: [ CanActivateInvitations ]
    },
    {
        path: 'applications',
        loadChildren: () => import('src/app/application/application.routes').then((m) => m.applicationRoutes),
        canActivate: [ CanActivateApplications ]
    },
    {
        path: 'forbidden',
        loadChildren: () => import('src/app/forbidden/forbidden.routes').then((m) => m.forbiddenRoutes)
    },
    {
        pathMatch: 'full',
        path: '**',
        component: AppComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppRoutingModule {}
