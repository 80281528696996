import { inject } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Params, Router, Event } from "@angular/router";
import { patchState, signalStore, withHooks, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { MsalService } from "@shared/authentication/msal.service";
import { filter, of, pipe, switchMap, tap, toArray } from "rxjs";
import { Menu } from "./app.component";
import { TranslateService } from "@ngx-translate/core";
import { withUserData } from "@shared/features/user-data.feature";

interface IAppState {
    isLoading: boolean,
    menuItems: Menu[],
    brand: string | undefined,
    brandName: string,
    theme: string,
    currentRouteLink: string
}

const initialState: IAppState = {
    isLoading: false,
    menuItems: [],
    brand: undefined,
    brandName: 'Munich Re',
    theme: 'set-midnight-blue-theme',
    currentRouteLink: ''
}

export const AppStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withUserData(),
  withMethods(
      (
          store,
          router = inject(Router),
          msalService = inject(MsalService)
      ) => ({
        login(): void {
            msalService.login();
        },
        logout(): void {
            msalService.logout();
            sessionStorage.removeItem('token');
        },
        goToRoute(link: string): void {
          router.navigateByUrl(link);
        },
        initMenu: rxMethod<{invitationsNavMenu: Menu, applicationsNavMenu: Menu}>(
              pipe(
                switchMap(({invitationsNavMenu, applicationsNavMenu}) =>
                  of(invitationsNavMenu, applicationsNavMenu).pipe(
                      filter(menuItem => menuItem.roles.some(role => store.roles().includes(role))),
                      toArray()
                  )
              ),
                tap(menuItems => {
                  patchState(store, { menuItems });
                  if (store.menuItems().length === 0) {
                    router.navigateByUrl('/forbidden');
                  }
                }),
              )
          ),
          observeQueryParams: rxMethod<Params>(
                pipe(
                  tap(params => {
                    const { theme, brandName } = getTheme(params.brand);
                    patchState(store, { theme, brandName, brand: params.brand });
                  }),
                )
            ),
          observeNavigationEndEvent: rxMethod<Event>(
                pipe(
                  filter((event) => event instanceof NavigationEnd),
                  tap(event => {
                    const route = event.url.slice(1).split('/');
                    patchState(store, { currentRouteLink: getCurrentRoute(route[0]) });
                  }),
                )
            ),
      })
  ),
  withHooks({
      onInit: (
        store,
        route = inject(ActivatedRoute),
        translate = inject(TranslateService),
        router = inject(Router)
      ) => {
          translate.use('en');
          store.observeQueryParams(route.queryParams);
          store.observeNavigationEndEvent(router.events);
  
          if (store.isLoggedIn()) {
            const invitationsNavMenu: Menu = { name: 'Invitations', link: '/invitations', icon: 'manage_users', roles: ['APIMManager', 'APIAdmin'] };
            const applicationsNavMenu: Menu = { name: 'Applications', link: '/applications', icon: 'basemaps', roles: ['APIMManager', 'APIAdmin', 'CustomerAdmin'] };
            store.initMenu({ invitationsNavMenu, applicationsNavMenu });
          }
      }
  })
)

function getTheme(brand: string | undefined) {
  switch (brand) {
    case 'amod':
      return { theme: 'set-cyan-theme', brandName: 'American Modern'};
    case 'ergo':
      return { theme: 'set-red-theme', brandName: 'ERGO'};
    case 'dkv':
      return { theme: 'set-dark-green-theme', brandName: 'DKV Seguros'};
    case 'hsb':
      return { theme: 'set-pale-blue-theme', brandName: 'HSB'};
    default:
      return { theme: 'set-midnight-blue-theme', brandName: 'Munich Re'};
  }
}

function getCurrentRoute(url: string): string {
    switch (url) {
        case 'invitations':
            return '/invitations';
        case 'applications':
        case '':
            return '/applications';
        default:
            return '';
    }
}