import { Injectable } from '@angular/core';
import { CanActivate, GuardResult, MaybeAsync, RedirectCommand, Router } from '@angular/router';
import { MsalService } from '@shared/authentication/msal.service';

@Injectable()
export class CanActivateInvitations implements CanActivate {
  constructor(private msalService: MsalService, private router: Router) {}
  canActivate(): MaybeAsync<GuardResult> {
    if (!this.msalService.isOnline()) {
      this.msalService.login();
      return false;
    }

    if (this.msalService.hasAnyRole('APIAdmin', 'APIMManager')) {
        return true;
    }

    return new RedirectCommand(this.router.parseUrl('/applications'));
  }
}
